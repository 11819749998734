/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import React, { FunctionComponent, ReactNode } from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams } from 'react-router-dom';
import { FormDefinitionSingleResponse, FormsContext, FormTypeSingleResponse, IDataSingleResponse, LookupCollectionResponse } from '@ngt/forms';
import MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver from '../resolver/MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver';
import { SWRConfiguration } from 'swr/dist/types';
import { IMedicalReviewForm, MedicalReviewDefinitionSingleResponse, MedicalReviewSingleResponse, QueryRecipientCollectionResponse, ReviewerSingleResponse, UpdatedFormPropertyCollectionResponse } from '../../api/dtos';
import MedicalReviewExtensionContext from '../../contexts/MedicalReviewExtensionContext';
import { useContext } from 'react';
import { IMedicalReviewFormMapping } from '../../utilities/IMedicalReviewFormMapping';
import { CoordinatingGroupSingleResponse, CountrySingleResponse, InstitutionSingleResponse, MasterGroupSingleResponse, PatientSingleResponse, TrialsExtensionContext } from '@ngt/forms-trials';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IMedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRouteProps extends RouteProps {
    formDefinitionCode?: string;
    patientStudyNumber?: string;
    medicalReviewRepeat?: number;
    formRepeat?: number;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    formMapping?: Record<string, IMedicalReviewFormMapping>;

    patientConfiguration?: Partial<SWRConfiguration<PatientSingleResponse, PatientSingleResponse>>;
    institutionConfiguration?: Partial<SWRConfiguration<InstitutionSingleResponse, InstitutionSingleResponse>>;
    countryConfiguration?: Partial<SWRConfiguration<CountrySingleResponse, CountrySingleResponse>>;
    coordinatingGroupConfiguration?: Partial<SWRConfiguration<CoordinatingGroupSingleResponse, CoordinatingGroupSingleResponse>>;
    masterGroupConfiguration?: Partial<SWRConfiguration<MasterGroupSingleResponse, MasterGroupSingleResponse>>;
    medicalReviewConfiguration?: Partial<SWRConfiguration<MedicalReviewSingleResponse, MedicalReviewSingleResponse>>;
    formDefinitionConfiguration?: Partial<SWRConfiguration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<SWRConfiguration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<SWRConfiguration<IDataSingleResponse<IMedicalReviewForm>, IDataSingleResponse<IMedicalReviewForm>>>;
    reviewerConfiguration?: Partial<SWRConfiguration<ReviewerSingleResponse, ReviewerSingleResponse>>;
    medicalReviewDefinitionConfiguration?: Partial<SWRConfiguration<MedicalReviewDefinitionSingleResponse, MedicalReviewDefinitionSingleResponse>>;
    formTypeConfiguration?: Partial<SWRConfiguration<FormTypeSingleResponse, FormTypeSingleResponse>>;
    queryRecipientsConfiguration?: Partial<SWRConfiguration<QueryRecipientCollectionResponse, QueryRecipientCollectionResponse>>;
    updatedFormPropertiesConfiguration?: Partial<SWRConfiguration<UpdatedFormPropertyCollectionResponse, UpdatedFormPropertyCollectionResponse>>;

    children?: ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute: FunctionComponent<IMedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRouteProps> = ({
    formDefinitionCode,
    patientStudyNumber,
    medicalReviewRepeat,
    formRepeat,
    resolveBeforeLoad,
    children,
    formMapping,

    patientConfiguration,
    institutionConfiguration,
    countryConfiguration,
    coordinatingGroupConfiguration,
    masterGroupConfiguration,
    formConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    medicalReviewConfiguration,
    medicalReviewDefinitionConfiguration,
    reviewerConfiguration,
    formTypeConfiguration,
    queryRecipientsConfiguration,
    updatedFormPropertiesConfiguration,

    ...routeProps
}) => {
    const params = useParams<Record<string, string>>();
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);
    const paramFormRepeatStr = params[formsContext.routeParameters.formRepeat];
    const paramMedicalReviewRepeatStr = params[medicalReviewExtensionContext.routeParameters.medicalReviewRepeat];
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    const paramPatientStudyNumber = params[trialsExtensionContext.routeParameters.patientStudyNumber];
    let paramFormRepeat = null;
    let paramMedicalReviewRepeat = null;

    try {
        if (paramFormRepeatStr) {
            paramFormRepeat = parseInt(paramFormRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid Form Repeat in route: ${paramFormRepeatStr}`)
    }

    try {
        if (paramMedicalReviewRepeatStr) {
            paramMedicalReviewRepeat = parseInt(paramMedicalReviewRepeatStr);
        }
    }
    catch (error) {
        console.error(`Invalid Form Repeat in route: ${paramMedicalReviewRepeatStr}`)
    }

    return (
        <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver
            formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? null}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber ?? null}
            medicalReviewRepeat={paramMedicalReviewRepeat ?? medicalReviewRepeat ?? null}
            formRepeat={paramFormRepeat ?? formRepeat ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            formMapping={formMapping}
            coordinatingGroupConfiguration={coordinatingGroupConfiguration}
            countryConfiguration={countryConfiguration}
            formConfiguration={formConfiguration}
            formDefinitionConfiguration={formDefinitionConfiguration}
            formTypeConfiguration={formTypeConfiguration}
            institutionConfiguration={institutionConfiguration}
            lookupConfiguration={lookupConfiguration}
            masterGroupConfiguration={masterGroupConfiguration}
            medicalReviewConfiguration={medicalReviewConfiguration}
            medicalReviewDefinitionConfiguration={medicalReviewDefinitionConfiguration}
            patientConfiguration={patientConfiguration}
            reviewerConfiguration={reviewerConfiguration}
            queryRecipientsConfiguration={queryRecipientsConfiguration}
            updatedFormPropertiesConfiguration={updatedFormPropertiesConfiguration}
        >
            {children}
        </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatResolver>
       )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute;